@import '~@fortawesome/fontawesome-free/css/all.css';

.Toastify__toast--success {
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
}

.Toastify__close-button--success {
  color: #155724;
}

.Toastify__toast--error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.Toastify__close-button--error {
  color: #721c24;
}
